<template>
  <img
    class="icon"
    :src="require(`@/assets/img/icons/${icon}.svg`)"
    :alt="icon"
  >
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
