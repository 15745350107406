<template>
  <span
    class="inline-flex"
  >
    <ProgressDot
      v-for="(active, index) in progressDots"
      :key="index"
      :active="active"
      :tooltip="tooltips[index]"
      :class="{
        'mr-1': index < progressDots.length - 1,
        'mr-0': index >= progressDots.length - 1,
      }"
    />
  </span>
</template>

<script>
import ProgressDot from './ProgressDot'
export default {
  name: 'ProgressDots',
  components: { ProgressDot },
  props: {
    total: {
      type: Number,
      default: 0
    },
    progress: {
      type: Number,
      default: 0
    },
    tooltips: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tooltip: {
        delay: 0,
        show: false,
        offset: 5,
        trigger: 'hover',
        classes: ['header-tooltip'],
        placement: 'bottom'
      },
    }
  },
  computed: {
    progressDots () {
      const dots = []
      for (let i = 0; i < this.total; i++) {
        if (i < this.progress) {
          dots.push(true)
        } else {
          dots.push(false)
        }
      }
      return dots
    }
  }
}
</script>

<style scoped>

</style>
