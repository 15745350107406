<template>
  <pose-transition>
    <Container
      v-if="open"
      class="slide-modal"
    >
      <Shade
        v-if="open"
        class="slide-modal__shade"
      >
        <div
          class="w-full h-full"
          @click="handleBackgroundClick($event)"
        />
      </Shade>
      <Modal
        v-if="open"
        class="slide-modal__content"
      >
        <slot />
      </Modal>
    </Container>
  </pose-transition>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose'

export default {
  name: 'SlideModal',
  components: {
    PoseTransition,
    Container: posed.div(),
    Shade: posed.div({
      enter: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
      }
    }),
    Modal: posed.div({
      enter: {
        right: 0,
      },
      exit: {
        right: -750,
      }
    })
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['background-click'],
  methods: {
    handleBackgroundClick(event) {
      if (!event.target.matches('.slide-modal__shade div')) return

      this.$emit('background-click')
    }
  }
}
</script>

<style scoped>
  .slide-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
  }

  .slide-modal__shade {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6)
  }

  .slide-modal__content {
    @apply p-8;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 700px;
  }
</style>
