<template>
  <div
    class="progress-dot w-2 h-2 border border-blue-washed-grey rounded-full cursor-pointer"
    :class="{
      'bg-blue-washed-grey': active,
      'bg-none': !active,
    }"
    ref="dot"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <div
      v-if="tooltip && hovering"
      class="progress-dot__tooltip"
      ref="tooltip"
    >
      {{ tooltip }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressDot',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hovering: false
    }
  },
  watch: {
    async hovering() {
      window.requestAnimationFrame(() => {
        this.setPosition()
      })

      if (this.hovering) {
        window.addEventListener('scroll', this.setPosition)
        window.addEventListener('resize', this.setPosition)

      }

      if (!this.hovering) {
        window.removeEventListener('scroll', this.setPosition)
        window.removeEventListener('resize', this.setPosition)
      }
    }
  },
  methods: {
    getOffset(element) {
      const rect = element.getBoundingClientRect()
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      }
    },
    setPosition() {
      if (!this.$refs.tooltip || !this.$refs.dot) return

      const { left, top } = this.$refs.dot.getBoundingClientRect()

      this.$refs.tooltip.style.left = `${left}px`
      this.$refs.tooltip.style.top = `${top}px`
    }
  }
}
</script>

<style scoped>
.progress-dot .progress-dot__tooltip {
  @apply px-2 py-1 bg-blue-54 rounded-full text-white font-bold uppercase;
  position: fixed;
  z-index: 10;
  transform: translateX(-50%) translateY(-1.5rem);
  line-height: 100%;
  font-size: 10px;
}
</style>
