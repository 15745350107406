<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <img
      src="@/assets/img/icons/loader.svg"
      width="50"
    >
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
